
.icon-search:before { content: '\e800'; } /* '' */
.icon-twitter:before { content: '\e801'; } /* '' */
.icon-facebook:before { content: '\e802'; } /* '' */
.icon-mail-alt:before { content: '\e803'; } /* '' */
.icon-location:before { content: '\e804'; } /* '' */
.icon-left-open:before { content: '\e805'; } /* '' */
.icon-right-open:before { content: '\e806'; } /* '' */
.icon-youtube-play:before { content: '\e807'; } /* '' */
.icon-plus:before { content: '\e808'; } /* '' */
.icon-people:before { content: '\e809'; } /* '' */
.icon-share:before { content: '\e80a'; } /* '' */
.icon-menu-1:before { content: '\e80b'; } /* '' */
.icon-help-circled:before { content: '\e80c'; } /* '' */
.icon-info-circled:before { content: '\e80d'; } /* '' */
.icon-calendar:before { content: '\e80e'; } /* '' */
.icon-down-dir:before { content: '\e80f'; } /* '' */
.icon-star:before { content: '\e810'; } /* '' */
.icon-linkedin-1:before { content: '\e811'; } /* '' */
.icon-star-empty:before { content: '\e812'; } /* '' */
.icon-linkedin:before { content: '\e813'; } /* '' */
.icon-pinterest:before { content: '\e814'; } /* '' */
.icon-github-circled:before { content: '\e815'; } /* '' */
.icon-flash:before { content: '\e816'; } /* '' */
.icon-link:before { content: '\e817'; } /* '' */
.icon-check:before { content: '\e818'; } /* '' */
.icon-plus-small:before { content: '\e819'; } /* '' */
.icon-minus:before { content: '\e81a'; } /* '' */
.icon-view:before { content: '\e81b'; } /* '' */
.icon-download-alt:before { content: '\e81c'; } /* '' */
.icon-search-1:before { content: '\e81d'; } /* '' */
.icon-phone-call:before { content: '\e81e'; } /* '' */
.icon-mail:before { content: '\e81f'; } /* '' */
.icon-fax:before { content: '\e820'; } /* '' */
.icon-download:before { content: '\e821'; } /* '' */
.icon-globe:before { content: '\e822'; } /* '' */
.icon-left:before { content: '\e823'; } /* '' */
.icon-instagram:before { content: '\e824'; } /* '' */
.icon-facebook-1:before { content: '\e825'; } /* '' */
.icon-chevron-right:before { content: '\e826'; } /* '' */
.icon-chevron-left:before { content: '\e827'; } /* '' */
.icon-youtube:before { content: '\e828'; } /* '' */
.icon-twitter-1:before { content: '\e829'; } /* '' */
.icon-search-2:before { content: '\e82a'; } /* '' */
.icon-right:before { content: '\e82b'; } /* '' */
.icon-quote:before { content: '\e82c'; } /* '' */
.icon-locker:before { content: '\e82d'; } /* '' */
.icon-print:before { content: '\e82e'; } /* '' */
.icon-unlock:before { content: '\e82f'; } /* '' */
.icon-cup:before { content: '\e837'; } /* '' */
.icon-form:before { content: '\e838'; } /* '' */
.icon-add-user:before { content: '\e839'; } /* '' */
.icon-star-1:before { content: '\e83a'; } /* '' */
.icon-icon-bulletin:before { content: '\e83f'; } /* '' */
.icon-up-open:before { content: '\f005'; } /* '' */
.icon-rss:before { content: '\f09e'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-play-circled:before { content: '\f144'; } /* '' */
.icon-check-square:before { content: '\f14a'; } /* '' */
.icon-down:before { content: '\f175'; } /* '' */
.icon-dot-circled:before { content: '\f192'; } /* '' */